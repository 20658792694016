@import url('https://fonts.googleapis.com/css?family=Raleway:200');
html{
    margin: 0;
    padding: 0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.shuffleButton{
    font-size: 1.1rem;
    font-weight: 400;
    border-radius: 50px;
    background-color: #BBBBBB;
    color: white;
}

.shuffleButton:hover{
    font-size: 1.1rem;
    font-weight: 400;
    border-radius: 50px;
    background-color: #E2D5D5;
    color: #865858;
}

.audioPlayer{
    margin-top: 40px;
    margin-bottom: 40px;
    max-width: 500px;
}

.imageThumbnail{
    border-radius: 20px;
    width: min(100%, 450px);
    box-shadow: 3px 1px rgba(255, 255, 255, 0.061);
    -webkit-box-shadow: 3px 3px 1px rgba(255, 255, 255, 0.061);
    -moz-box-shadow: 3px 3px 1px rgba(255, 255, 255, 0.061);
    cursor: pointer;
}

.imageThumbnailOverlay{
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.125);
    cursor: pointer;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    scrollbar-width: none;
}

.overlayGlass{
    /* backdrop-filter: blur(1px) saturate(127%);
    -webkit-backdrop-filter: blur(1px) saturate(127%);
    background-color: rgba(255, 255, 255, 0.47); */
    height: 100%;
    width: 100%;
    /* background-color: #A21232; */
}

.albumName{
    font-weight: 500;
    margin: 15px auto;
    color: white;
    font-family: 'Arvo', serif;
    margin-top: 10px;
    font-size: 1.1rem;
    margin-bottom: 0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.songName{
    font-weight: 600;
    margin: 5px auto;
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
    font-family: 'Source Code Pro', monospace;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.toggleIcon{
}

.toggleButtons{
    background-color: #F1F3F4;
    border: none;
    border-radius: 30px;
    height: min(60px, 10%);
    width: min(60px, 10%);
    margin: auto 3px;
}

.playlistCard{
    backdrop-filter: blur(5px) saturate(118%);
    -webkit-backdrop-filter: blur(5px) saturate(118%);
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    max-width: 540px;
    max-height: 800px;
    scrollbar-width: none;
}

.playlistCardOverlay{
    backdrop-filter: blur(5px) saturate(118%);
    -webkit-backdrop-filter: blur(5px) saturate(118%);
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 12px;
    border: 1px solid rgba(209, 213, 219, 0.3);
    max-width: 540px;
    max-height: 800px;
    z-index: 9;
    scrollbar-width: none;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display{
    display: none;
}

.theMessage{
    word-wrap: break-word;
    padding: min(20px, 6%) min(15px, 4%);
    font-family: 'Patrick Hand', cursive;
    font-size: 1.5rem;
    user-select:none;
    scrollbar-width: none;
    z-index: 30;
    /* background-color: #022E57;
    color: #FFF5FD; */
}

/*
#621a83 #ffffff
#FAFAFA #9D5C0D
#121212 #F5F5F5
#022E57 #FFF5FD
*/


.theMessage::-webkit-scrollbar {
    display: none;
}
.theMessage {
    -ms-overflow-style: none;
    scrollbar-width: none;
    scrollbar-width: none;
}

.theStoryInstances{
}

.theStories2{
    background: rgba( 1, 0, 0, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    min-width: 100%;
    min-height: 100%;
    
}

.thephotoEffect{
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 5rem;
    margin: 20px;
    border-radius: 50px;
    cursor: pointer;
}

.cardImage{
    border-radius: 50px 50px 10px 10px;
    pointer-events: none;
}

.theStories{
    overflow-y: auto;
    padding: 1%;
}

.storyKaTitle{
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.storyBack{
    background: #e0526f;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFEDBC, #e45370);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFEDBC, #e0526f); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    padding: 2%;
    
}
.storyBack2{
    background: rgba( 1, 0, 0, 0.2 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 13.5px );
    -webkit-backdrop-filter: blur( 13.5px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    max-width: 700px;
    width: 100%;
    padding: 20px 30px;
}

.chatSection{
    height: 80vh;
    background-color: #ffffff;
    border-radius: 30px;
    padding: 10px;
    margin-top: 10px;
}

.theChatMessage{
    background-color: #f2f2f2;
    border-radius: 20px;
    padding: 10px 20px;
    display: inline-block;
    text-align: left;
    margin-bottom: 8px;
    max-width: min(530px, 82vw);
    font-family: 'Josefin Sans', sans-serif;
    font-size: 1.2rem;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}


::-webkit-scrollbar {
    display: none;
}

.scrollbar {
    overflow-y: scroll;
  }
 .chatBubble{
    text-align: left;
 }

.storyPageTitle{
    color: white;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.titleStories{
    font-size: 3rem;
}

.somethingToRememberMe{
    font-weight: 500;
    font-size: 1.6rem;
    margin-bottom: 20px;
}

.happyBirthday{
    font-family: 'Great Vibes', cursive;
    font-size: 4rem;
    font-weight: 500;
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
}

.landingPhoto{
    padding: 15px;
    border-radius: 50px;
    max-width: 520px;
}

.lb{
    text-align: center;
}

.landingButtons1{
    margin: 60px auto 20px auto;
    padding: min(15px, 2%) min(10%, 70px);
    width: 70%;
    border-radius: 30px;
    border-width: 0;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.8rem;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    color: #2e2e2e;
}

.landingButtons1:hover {
    background-color: #2EE59D;
    box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
    color: #fff;
    transform: translateY(7px);
  }

  .landingButtons2{
    margin: 40px auto 20px auto;
    padding: min(15px, 2%) min(10%, 70px);
    width: 70%;
    border-radius: 30px;
    border-width: 0;
    font-family: 'Orbitron', sans-serif;
    font-size: 1.8rem;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
    color: #2e2e2e;
}

.landingButtons2:hover {
    background-color: #6c0fdd;
    box-shadow: 0px 15px 20px rgba(73,26,158, 0.98);
    color: #fff;
    transform: translateY(-7px);
  }

.bdayMessage{
    text-align: left;
}

.theBdayMessageWindow{
    overflow-y: auto;
    max-width: fit-content;
    z-index: 3;
    max-height: 500px;
    margin: min(1%, 10px);
    padding: 5px;
    font-size: 1.2rem;
}

.theGiftMessageWindow{
    overflow-y: auto;
    max-width: fit-content;
    max-height: 200px;
    margin: 10px;
    padding: 5px;
    font-size: 1.2rem;
}

.giftMessage{
    text-align: left;
}
.bdayMessage,.giftMessage{
    color: #fff;
    font-family: 'Raleway';
    font-size: 1.1em;
    margin: 2% 0%;
    background: #2e2e2e;
}
